import React, { useMemo } from 'react'
import { get } from 'lodash'
// import ReactPlayer from 'react-player'
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'
// import '../../../node_modules/uikit/dist/js/uikit.js'
// import { useWindowSizes } from '@hooks/window'
// import { Waypoint } from 'react-waypoint'
// import LogoImg from '../../images/logo.svg'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'

const SecondaryHeader = ({ data, index }) => {

  // console.log('[SecondaryHeader.js] data', data)

  const title = useMemo(() => (
    get(data, 'primary.title1[0].text')
  ), [data])

  const sub_title = useMemo(() => (
    get(data, 'primary.sub_title[0].text')
  ), [data])

  const image = useMemo(() => (
    get(data, 'primary.background_image')
  ), [data])

  const text = useMemo(() => (
    get(data, 'primary.text')
  ), [data])

  return(
    <section
      key={index}
      className={"secondary_header"}>
      
      <div className="secondary_header__container">

        <div className="row">
          <div className="col col4">
            <div className="secondary_header__head">
              {title !==null && title !== "" ?
                <h2 className="h1 special-font">
                  {title}
                </h2>
              : null}
              {sub_title !== null && sub_title !== "" ?
                <h1 className="h4 secondary_header__head__title">
                  {sub_title}
                </h1>
              : null}
            </div>
          </div>
    

        {text !== null && text !== "" ?
       
            <div className="col col4">
              <div className="secondary_header__box font-book">
                <RichText
                  render={text}
                  linkResolver={linkResolver}
                />
              </div>
            </div>
         
        : null}
        </div>

      </div>

      <div className="secondary_header__background">
        <img src={image?.url} alt={image?.alt && title} />
      </div>
      
    </section>
  )  
}

export default SecondaryHeader
