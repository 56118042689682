import React, { useMemo } from 'react'
import { get } from 'lodash'
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'
// import '../../../node_modules/uikit/dist/js/uikit.js'
// import { useWindowSizes } from '@hooks/window'
// import { Waypoint } from 'react-waypoint'
// import LogoImg from '../../images/logo.svg'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'

const LeadAndText = ({ data, index }) => {

  // console.log('[LeadAndText.js] data', data)

  const icon = useMemo(() => (
    get(data, 'primary.image')
  ), [data])

  const title = useMemo(() => (
    get(data, 'primary.title1[0].text')
  ), [data])

  const cards = useMemo(() => (
    get(data, 'fields')
  ), [data])

  const color_mode = useMemo(() => (
    get(data, 'primary.color_mode')
  ), [data])

  let color_mode_class = ""
  if(color_mode === true) {
    color_mode_class = " lead_text-light"
  }

  const renderCard = (card, index) => {

    const title = get(card, 'title1[0].text')
    const text = get(card, 'text')

    return (
      <div className="lead_text__text_box font-book" key={index}>
        <h3>{title}</h3>
        <RichText 
          render={text}
          linkResolver={linkResolver}
        />
      </div>
    )
  }

  return(
    <section
      key={index}
      className={"lead_text"+color_mode_class}>
     <div className="row">
      <div className="col col12 lead_text__lead">
        <img src={icon?.url} alt={icon?.alt ?? title} />
        <h3 className="h1 special-font lead_text__lead__title">
          {title}
        </h3>
      </div>
      <div className="col col6 hidden_ipad"></div>

      <div className="col col6 lead_text__text lead_text__text_detail">
        <div className="lead_text__text_container">
          {cards.map((card, index) => (
            renderCard(card, index)
          ))} 
        </div>
      </div>

     </div>
    </section>
  )  
}

export default LeadAndText
