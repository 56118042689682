import React, { useMemo } from 'react'
import { get } from 'lodash'
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'
// import '../../../node_modules/uikit/dist/js/uikit.js'
// import { useWindowSizes } from '@hooks/window'
// import { Waypoint } from 'react-waypoint'
// import LogoImg from '../../images/logo.svg'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'

const PeopleCards = ({ data, index }) => {

  // console.log('[PeopleCards.js] data', data)

  const icon = useMemo(() => (
    get(data, 'primary.image')
  ), [data])

  const title = useMemo(() => (
    get(data, 'primary.title1[0].text')
  ), [data])

  const cards = useMemo(() => (
    get(data, 'fields')
  ), [data])

  const color_mode = useMemo(() => (
    get(data, 'primary.color_mode')
  ), [data])

  let color_mode_class = ""
  if(color_mode === true) {
    color_mode_class = " people_cards-light"
  }

  const renderCard = (card, index) => {

    const name = get(card, 'name[0].text')
    const bio = get(card, 'bio')
    const photo = get(card, 'photo')

    return (
      <div className="people_cards__cards__card" key={index}>
        {photo ?
          <img 
            src={photo?.url}
            alt={photo?.alt ?? name} />
        : null}

        <div className="people_cards__cards__card__box font-book">
          <h6>{name}</h6>
          <RichText 
            render={bio}
            linkResolver={linkResolver}
          />
        </div>
      </div>
    )
  }

  return(
    <section
      key={index}
      className={"people_cards"+color_mode_class}>
      
      <div className="row">
        <div className="col col5 people_cards__lead">
          <img src={icon?.url} alt={icon?.alt ?? title} />
          <h3 className="h1 special-font people_cards__lead__title">
            {title}
          </h3>
        </div>
      </div>

      <div className="people_cards__cards">
        <div className="people_cards__text_container">
          {cards.map((card, index) => (
            renderCard(card, index)
          ))}
         
        </div>
      </div>

    </section>
  )  
}

export default PeopleCards
