import React, { useMemo } from 'react'
import { get } from 'lodash'
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'
// import '../../../node_modules/uikit/dist/js/uikit.js'
// import { useWindowSizes } from '@hooks/window'
// import { Waypoint } from 'react-waypoint'
// import LogoImg from '../../images/logo.svg'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'
import { useWindowSizes } from '@hooks/window'
import Image from './Image'

const EditorialTextImage = ({ data, index }) => {

  // console.log('[EditorialTextImage.js] data', data)

  const { isIpadPro, isIpad, isIphonePlus, isDesktop } = useWindowSizes()

  const image = useMemo(() => (
    get(data, 'primary.image')
  ), [data])

  // Image size
  let imgWidth = 1920
  isIphonePlus  ? imgWidth = 675 :
  isIpad        ? imgWidth = 768 :
  isIpadPro     ? imgWidth = 1024 / 2 :
  isDesktop     ? imgWidth = 1440 / 2 :
  imgWidth = 1920 / 2

  const overlay_image = useMemo(() => (
    get(data, 'primary.overlay_illustration')
  ), [data])

  const text = useMemo(() => (
    get(data, 'primary.text')
  ), [data])

  const aligment = useMemo(() => (
    get(data, 'primary.image_aligment')
  ), [data])

  let aligment_class = ""
  if(aligment === "Left") {
    aligment_class = " editorial_text_image-rev"
  }

  return(
    <section
      key={index}
      className={"editorial_text_image"+aligment_class}>
      
      <div className="row row-justify">
        
        <div className="col col5 editorial_text_image__text font-book">
          <RichText
            render={text}
            linkResolver={linkResolver}
          />
        </div>

        <div className="col col6 editorial_text_image__image">
          
          <div className="editorial_text_image__image__inner">
            {overlay_image ?
              <img className="editorial_text_image__overlay" src={overlay_image?.url} alt={overlay_image?.alt} />  
            : null}
            
            {/* <img src={image?.url} alt={image?.alt ?? 'Ocafi'} /> */}
            <Image image={image} width={imgWidth} height="full" />

          </div>
        </div>
      </div>
      
    </section>
  )  
}

export default EditorialTextImage
