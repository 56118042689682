import React, { useMemo } from 'react'
import { get } from 'lodash'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'

const EditorialTextImageContact = ({ data, index }) => {

  // console.log('[EditorialTextImageContact.js] data', data)

  const image = useMemo(() => (
    get(data, 'primary.image')
  ), [data])

  const overlay_image = useMemo(() => (
    get(data, 'primary.image_overlay__optional_')
  ), [data])

  const title = useMemo(() => (
    get(data, 'primary.title1[0].text')
  ), [data])

  const left_text = useMemo(() => (
    get(data, 'primary.left_text')
  ), [data])

  const right_text = useMemo(() => (
    get(data, 'primary.right_text')
  ), [data])

  const aligment = useMemo(() => (
    get(data, 'primary.image_aligment')
  ), [data])

  let aligment_class = ""
  if(aligment === "Left") {
    aligment_class = " editorial_text_image-rev"
  }

  return(
    <>
      <section
        key={index}
        className={"editorial_text_image editorial_text_image-contact"+aligment_class}>
        
        <div className="row row-justify">
          
          <div className="col col5 editorial_text_image__text font-book">
            <h3>
              {title}
            </h3>
            <div className="editorial_text_image__text__container">
              <div className="editorial_text_image__text__left">
                <RichText
                  render={left_text}
                  linkResolver={linkResolver}
                />
              </div>
              <div className="editorial_text_image__text__right">
                <RichText
                  render={right_text}
                  linkResolver={linkResolver}
                />
              </div>
            </div>
            
          </div>

          <div className="col col6 editorial_text_image__image">
            
            <div className="editorial_text_image__image__inner">
              {overlay_image ?
                <img className="editorial_text_image__overlay" src={overlay_image?.url} alt={overlay_image?.alt} />  
              : null}
              <img src={image?.url} alt={image?.alt ?? title} />
            </div>
          </div>
        </div>      
      </section>
      
      {/* {show_form === true ?
        <ContactForm data={data} />
      : null} */}
      
    </>
  )  
}

export default EditorialTextImageContact
